/**
 * Define some frequent-use constants
 * for easier import inside components
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

export const tagManagerArgs = {
	gtmId: 'GTM-T55FM5G'
}

export const googleAnalyticsId = 'G-CF7D0J1E18'
// Languages
export const languages = {
	en: 'en',
	fi: 'fi'
}

// routerPath
export const routerPath = {
	home: '/',
	register: '/register',
	resetPasswordSuccess: '/password/success',
	resetPasswordFail: '/user/password/failed',
	forgetPassword: '/forgotPassword',
	form: '/form',
	payment: '/payment',
	paymentSuccess: '/payment/success',
	account: '/account',
	show: '/shows'
}

// pageTitle
export const pageTitle = {
	home: 'Home',
	register: 'Register',
	form: 'Form',
	payment: 'Payment',
	account: 'Account',
	resetPasswordSuccess: 'Reset Password Success',
	resetPasswordFail: 'Reset Password Fail',
	forgetPassword: 'Forget Password',
	show: 'Show'
}

// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'root'

// Color code
export const primaryColor = '#fc4f00'

export const radioButtonColor = '#fff'

export const PODIT_BACKEND_URL =
	process.env.PODIT_BACKEND_URL ||
	'https://podit-gateway-production-dot-podit-303706.ey.r.appspot.com'

// app ids
export const FACEBOOK_APP_ID = '423261792304188'
export const FACEBOOK_FIELDS = 'name,email'

export const GOOGLE_CLIENT_ID =
	'914903922307-hrdjuvdk61oibj98fal5lfblbqsb25qp.apps.googleusercontent.com'

export const APPLE_CLIENT_ID = 'com.podit.web'
export const APPLE_REDIRECT_URI =
	'https://podit-user-production-dot-podit-303706.ey.r.appspot.com/auth/signin/apple/callback'
export const APPLE_SIGNUP_URI =
	'https://podit-user-production-dot-podit-303706.ey.r.appspot.com/auth/signup/apple/callback'
export const APPLE_TEAM_ID = 'L3262566M9'
export const APPLE_KEY_ID = 'ZVN4RL8NR8'

export const APPLE_RESPONSE_TYPE = 'code id_token'
export const APPLE_RESPONSE_MODE = 'form_post'

export const SUBSCRIPTION_PERIOD = {
	MONTHLY: 'Kuukausittain',
	YEARLY: 'Vuosittain',
	TEST: 'Test',
	BASIC: 'Basic'
}

export const SUBSCRIPTION_PERIOD_COSTS = {
	Kuukausittain: 55.5,
	Vuosittain: 666
}

export const STRIPE_API_KEY =
	process.env.NODE_ENV === 'development'
		? 'pk_test_51IYr0wEX0nb1Nxwe7eye12kZ1yg3n0gIWaCRBoDNlyDj5kpmCQekthzI4ZSdEZJm8j1BmP3kR0oCNC8tO8CmeELo00vLw5vXCM'
		: 'pk_live_51IYr0wEX0nb1Nxwe4EjUl9I1oriKI1Wneqpq9XQuBwK5kyYDZVQ8BW4SIq69c15Esa8QvTCNdT61wVVNN9Iy0dhf004Fon9zcM'

// Custom stylings for stripe card element
export const CARD_ELEMENT_OPTIONS = {
	iconStyle: 'solid',
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			backgroundColor: 'white',
			fontWeight: 500,
			height: '100%',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4'
			}
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	},
	mode: 'subscription'
}
export const colors = {
	theme: 'lightcoral'
}

export const SUCCESS_STATUS_CODE = 200

export const SUBSCRIPTION_PLANS_IDS = {
	Kuukausittain: 'prod_JLJrxlXXwyzPRU',
	Vuosittain: 'prod_JLhxFQySB9JlNq',
	Test: 'prod_JSllqc0GePFAXg',
	Basic: 'prod_JHy8Bih7E7SSLS'
}

export const PAYMENT_STATUS = {
	SUCESS: 'success',
	FAIL: 'fail'
}

export const ACCEPT_TERMS_TEXT = 'Hyväksy ensin käyttäjäehdot'
export const TERMS_REDIRECT_URL = 'https://podit.fi/kayttoehdot'
export const CONDITIONS_REDIRECT_URL = 'https://podit.fi/tietosuojaseloste'
