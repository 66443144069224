import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

const EpisodeListing = ({ episodes }) => {
	const { url } = useRouteMatch()

	return (
		<>
			<h2>Kaikki jaksot</h2>
			{episodes.map(episode => (
				<Link
					to={`${url}/episodes/${episode.id}`}
					style={{ color: '#fff', textDecoration: 'none' }}
					key={episode.id}
				>
					<div className='episode'>
						<h6>{episode.title}</h6>
						<p> {episode.description}</p>
					</div>
				</Link>
			))}
		</>
	)
}

export default EpisodeListing
