import React, { useState, useEffect } from 'react'
import Button from 'components/Button'
import axios from 'axios'
import { PODIT_BACKEND_URL } from '../../constants'
import ErrorModal from './ErrorView'
import { validateCouponLength } from '../../pages/RegistrationForm/validation'
import Input from 'components/Input'
import useSnackbarInternal from '../../hooks/snackbar'

const CouponForm = ({ setCoupon }) => {
	const [error, setError] = useState(null)
	const { send } = useSnackbarInternal()
	const [showErrorModal, setShowErrorModal] = useState(false)
	const [value, setValue] = useState('')

	useEffect(() => {
		setValue('')
		setError('')
	}, [])

	const handleChange = e => {
		const { value } = e.target
		setError('')
		setValue(value)
	}

	const formInvalid = () => {
		const error = validateCouponLength(value)
		setError(error)
		return error
	}

	const validateCoupon = async e => {
		e.preventDefault()
		setError(null)
		if (formInvalid()) {
			return
		}

		try {
			const {
				data: { data: payload }
			} = await axios.post(`${PODIT_BACKEND_URL}/payments/coupon`, {
				couponCode: value
			})
			setCoupon(payload)
		} catch (e) {
			if (e?.response?.status !== 400) {
				send(e.message)
				setShowErrorModal(true)
			} else {
				setShowErrorModal(true)
			}
		}
	}

	return (
		<div className='coupon'>
			{showErrorModal ? (
				<ErrorModal onModalClose={setShowErrorModal}></ErrorModal>
			) : (
				<form className='coupon__formSection-fields' onSubmit={validateCoupon}>
					<div className='coupon__description-details'>
						<h2 className='heading'>Business-podcastit ja -äänikirjat</h2>
						<p className='text'>
							Oletko saanut lahjakortin Podit-tilin avaamista varten?
						</p>
						<p className='text'>Lunasta lahjakorttisi</p>
					</div>

					<Input
						value={value}
						onChange={handleChange}
						label='Koodi'
						error={error}
						className='field'
					/>
					<Button
						type='submit'
						text='Lunasta'
						containerClassName='coupon__coupon-button'
					/>
				</form>
			)}
		</div>
	)
}
export default CouponForm
