/**
 * Navbar component
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import {
	Link,
	useHistory
} from 'react-router-dom'
import logo from 'assets/logo.svg'
import { useSelector } from 'react-redux'
import { routerPath } from '../../constants'
import LoginModal from '../LoginModal'
import { loginSelectors } from '../../reducers/login'
import TextButton from '../TextButton'

const Nav = ({ ActionComponent }) => {
	const history = useHistory()

	const isAuthenticated = useSelector(state =>
		loginSelectors.getIsLogin(state.login)
	)

	const openAccountPage = () => {
		history.push(routerPath.account)
	}
	return (
		<div className='nav-wrapper'>
			<Link to='/'>
				<img src={logo} className='nav-logo' />
			</Link>

			{ActionComponent ? (
				ActionComponent
			) : isAuthenticated ? (
				<TextButton onClick={openAccountPage}>Oma tili</TextButton>
			) : (
				<LoginModal />
			)}
		</div>
	)
}
export default Nav
