/**
 * Declare all types and export
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Login
export const loginTypes = {
	changeField: 'LOGIN_CHANGE_FIELD',
	facebookLogin: 'FACEBOOK_LOGIN',
	facebookLoginSuccess: 'FACEBOOK_LOGIN_SUCCESS',
	facebookLoginFail: 'FACEBOOK_LOGIN_FAIL',
	googleLogin: 'GOOGLE_LOGIN',
	googleLoginSuccess: 'GOOGLE_LOGIN_SUCCESS',
	googleLoginFail: 'GOOGLE_LOGIN_FAIL',
	forgetPassword: 'FORGET_PASSWORD',
	forgetPasswordSuccess: 'FORGET_PASSWORD_SUCCESS',
	forgetPasswordFail: 'FORGET_PASSWORD_FAIL',
	resetPassword: 'RESET_PASSWORD',
	resetPasswordSuccess: 'RESET_PASSWORD_SUCCESS',
	resetPasswordFail: 'RESET_PASSWORD_FAIL',
	emailLogin: 'EMAIL_LOGIN',
	emailLoginSuccess: 'EMAIL_LOGIN_SUCCESS',
	emailLoginFail: 'EMAIL_LOGIN_FAIL',
	resetFields: 'LOGIN_RESET_FIELDS'
}

// User
export const userTypes = {
	getUser: 'USER_GET_USER',
	getUserSuccess: 'USER_GET_USER_SUCCESS',
	getUserFail: 'USER_GET_USER_FAIL',
	getNewJWT: 'USER_GET_NEW_JWT',
	getNewJWTSuccess: 'USER_GET_NEW_JWT_SUCCESS',
	getNewJWTFail: 'USER_GET_NEW_JWT_FAIL',
	resetFields: 'USER_RESET_FIELDS',
	changeField: 'USER_CHNAGE_FIELD',
	getSubscriptionPlan: 'USER_GET_SUBSCRIPTION_PLAN',
	getSubscriptionPlanSuccess: 'USER_GET_SUBSCRIPTION_PLAN_SUCCESS',
	getSubscriptionPlanFail: 'USER_GET_SUBSCRIPTION_PLAN_FAIL',
	openStripePortal: 'USER_OPEN_STRIPE_PORTAL'
}

// From
export const registrationFormTypes = {
	changeField: 'REGISTRATION_FORM_CHANGE_FIELD',
	resetError: 'REGISTRATION_FROM_RESET_ERROR',
	resetFields: 'REGISTRATION_FROM_RESET_FIELDS',
	onFormSubmit: 'REGISTRATION_FORM_ON_FORM_SUBMIT',
	onFormSubmitSuccess: 'REGISTRATION_FORM_ON_FORM_SUBMIT_SUCCESS',
	onFormSubmitFail: 'REGISTRATION_FORM_ON_FORM_SUBMIT_FAIL'
}
// Payment
export const paymentTypes = {
	changeField: 'PAYMENT_FIELD_CHANGE_FIELD',
	getPaymentPlans: 'PAYMENT_GET_PAYMENT_PLANS',
	getPaymentPlansSuccess: 'PAYMENT_GET_PAYMENT_PLANS_SUCCESS',
	getPaymentPlansFail: 'PAYMENT_GET_PAYMENT_PLANS_FAIL',
	subscribe: 'PAYMENT_SUBSCRIBE',
	subscribeSuccess: 'PAYMENT_SUBSCRIBE_SUCCESS',
	subscribeFail: 'PAYMENT_SUBSCRIBE_FAIL',
	resetFields: 'PAYMENT_RESTE_FIELDS',
	validateCoupon: 'VALIDATE_COUPON',
	validateCouponFail: 'VALIDATE_COUPON_FAIL',
	validateCouponSuccess: 'VALIDATE_COUPON_SUCCESS',
	resetError: 'PAYMENT_COUPON_FIELD_ERROR'
}

