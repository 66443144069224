/**
 * Main container
 *
 * @author Sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2021
 */
import React, { Suspense, useEffect } from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import Routes from './Routes'

import { userActions } from 'actions'
import { userSelectors } from 'reducers'
// Components
import Loader from 'components/Loader'

import SnackbarProvider from 'react-simple-snackbar'

// constants
import { tagManagerArgs } from 'constants'
import TagManager from 'react-gtm-module'
import axios from 'axios'

axios.interceptors.request.use(config => {
	config.headers = {
		Authorization: `Bearer ${localStorage.getItem('token')}`
	}

	return config
})

const App = props => {
	const getUser = () => {
		const {
			history: {
				location: { search }
			},
			dispatch
		} = props
		const token = qs.parse(search)['?token'] || localStorage.getItem('token')
		if (token) {
			localStorage.setItem('token', token)
			dispatch(userActions.getUser(token))
		}
	}

	useEffect(() => {
		const disableIt =
			props.location.search.includes('disableTracking=true') ||
			props.location.search.includes('disableTracking=undefined') ||
			sessionStorage.getItem('disableTracking')

		if (disableIt) {
			sessionStorage.setItem('disableTracking', true)
		}

		if (!disableIt) {
			if (props.user) {
				const { user, subscription } = props
				TagManager.initialize({
					...tagManagerArgs,
					dataLayer: {
						...tagManagerArgs.dataLayer,
						userID: user.id,
						subscription_type: user.planId,
						userRole: user.userType,
						login_status: 'logged_in',
						subscription_status: subscription ? subscription.status : ''
					}
				})
			} else {
				TagManager.initialize({ ...tagManagerArgs })
			}
		}
	})

	const {
		history: { location }
	} = props

	useEffect(() => {
		if (!props.user) {
			getUser()
		}
	}, [location.pathname])

	return (
		<SnackbarProvider>
			<Suspense fallback={<Loader />}>
				<div className='app__content'>
					<Router>
						<Routes />
					</Router>
				</div>
			</Suspense>
		</SnackbarProvider>
	)
}

const mapStateToProps = state => ({
	user: userSelectors.getUser(state.user),
	subscription: userSelectors.getSubscription(state.user)
})

export default connect(mapStateToProps)(withRouter(App))
