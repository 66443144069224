import React from 'react'
import Button from '../../components/Button'
import Layout from '../../components/layouts/CoreLayout'

const NotFoundView = () => {
	const handleClick = () => {
		location.reload()
	}
	return (
		<Layout>
			<div style={{ marginBottom: 10, marginTop: 10 }}>
				Tietojen haku epäonnistui!
			</div>
			<Button onClick={handleClick} text='Yritä uudelleen ' />
		</Layout>
	)
}

export default NotFoundView
