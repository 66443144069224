/**
 * Forget component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React, { useState } from 'react'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
// actions
import { loginActions } from 'actions'
// Components
import withLoadingScreen from 'components/Loading'
import Input from 'components/Input'
import Button from 'components/Button'
import { SUCCESS_STATUS_CODE } from '../../constants'
import useSnackbar from '../../hooks/snackbar'

const ForgetPassword = ({ showLoading, hideLoading }) => {
	const [isPasswordReset, setStatus] = useState(false)
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { send } = useSnackbar()
	const { token } = queryString.parse(location.search)
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const onPasswordChange = e => {
		setPassword(e.target.value)
	}
	const onConfirmPasswordChange = e => {
		setConfirmPassword(e.target.value)
	}
	const onSubmit = async e => {
		e.preventDefault()

		const isFormDisabled =
			confirmPassword !== password ||
			!password ||
			!confirmPassword ||
			password.length < 8

		if (isFormDisabled) {
			send('Salasanat eivät täsmää')
			return
		}
		try {
			showLoading()
			const res = await dispatch(
				loginActions.resetPassword(token, { password })
			)
			if ((res.payload.status = SUCCESS_STATUS_CODE)) {
				setStatus(true)
			}
		} finally {
			hideLoading()
		}
	}

	return (
		<div className='forget-password'>
			<div className='forget-password__content'>
				{isPasswordReset ? (
					<div>
						<p>Salasana vaihdettu onnistuneesti.</p>
						<Button
							text='Kirjaudu sisään'
							onClick={() => history.push('/account')} // need to redirect to app
						/>
					</div>
				) : (
					<form autoComplete='off'>
						<Input
							label='Salasana'
							value={password}
							onChange={onPasswordChange}
							className='field'
							type='password'
						/>
						<Input
							label='Salasana uudelleen'
							value={confirmPassword}
							onChange={onConfirmPasswordChange}
							className='field'
							type='password'
						/>
						<span>
							{' '}
							<i>*Salasanan täytyy olla yli 8 merkkiä pitkä</i>{' '}
						</span>
						<Button type='submit' text='Lähetä' onClick={onSubmit} />
					</form>
				)}
			</div>
		</div>
	)
}

export default withLoadingScreen(ForgetPassword)
