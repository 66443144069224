/**
 * Login reducer
 *
 * @author sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2021
 */
import {
	paymentTypes as types,
	registrationFormTypes
} from '../actions/types'
import { normalizeError } from './utils'

const initialState = {
	email: '',
	name: '',
	firstName: '',
	lastName: '',
	country: '',
	coupon: '',
	couponError: '',
	loading: false,
	error: null,
	paymentPlans: [],
	isPaymentSuccess: false
}

export const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value
			}

		case registrationFormTypes.onFormSubmitSuccess:
			return {
				...state,
				email: action.payload.data.data.savedUser.email
			}
		case types.getPaymentPlansSuccess:
			return {
				...state,
				paymentPlans: action.payload.data.data
			}
		case types.subscribeSuccess:
			return {
				...state,
				isPaymentSuccess: true
			}
		case types.subscribeFail: {
			return {
				...state,
				error: normalizeError(action.error)
			}
		}
		case types.validateCouponSuccess:
			return {
				...state,
				isValidCoupon: true
			}
		case types.validateCouponFail: {
			return {
				...state,
				error: normalizeError(action.error)
			}
		}
		case types.resetFields: {
			return initialState
		}
		default:
			return state
	}
}

export const paymentSelectors = {
	getEmail: (state = initialState) => state.email,
	getName: (state = initialState) => state.name,
	getFirstName: (state = initialState) => state.firstName,
	getLastName: (state = initialState) => state.lastName,
	getCountry: (state = initialState) => state.country,
	getPaymentPlans: (state = initialState) => state.paymentPlans,
	getError: (state = initialState) => state.error,
	getCoupon: (state = initialState) => state.coupon,
	getCouponError: (state = initialState) => state.couponError
}
