/**
 * User action creators
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { userTypes as types } from './types'

const getUser = () => ({
	type: types.getUser,
	payload: {
		request: {
			method: 'GET',
			url: `/user`
		},
		params: {
			relations: ["linkedAccounts"]
		}

	}

})
const getNewJWT = refreshToken => {
    return {
        type: types.getNewJWT,
        payload: {
            request: {
                method: 'GET',
                url: `/auth/token/refresh`,
                params: {
                    refreshToken
                }
            }
        }
    }
}

const resetFields = () => {
	return {
		type: types.resetFields
	}
}

const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

const getSubscriptionPlan = () => {
	return {
		type: types.getSubscriptionPlan,
		payload: {
			request: {
				method: 'GET',
				url: '/payments/subscription'
			}
		}
	}
}

const openStripePortal = () => {
	return {
		type: types.openStripePortal,
		payload: {
			request: {
				method: 'GET',
				url: '/payments/portal'
			}
		}
	}
}

export const userActions = {
	getUser,
	resetFields,
	changeField,
	getNewJWT,
	getSubscriptionPlan,
	openStripePortal
}