/**
 * Login action creator
 *
 * @author sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2021
 */

import { paymentTypes as types } from './types'

const changeField = (name, value) => {
    return {
        type: types.changeField,
        name,
        value
    }
}
const getPaymentPlans = () => ({
    type: types.getPaymentPlans,
    payload: {
        request: {
            method: 'GET',
            url: `/payments`
        }
    }
})

const subscribe = (data) => {
    return {
        type: types.subscribe,
        payload: {
            request: {
                method: 'POST',
                url: `/payments/subscribe`,
                data
            }
        }
    }
}

const resetError = () => {
    return {
        type: types.resetError
    }
}

const validateCoupon = (data) => ({
    type: types.validateCoupon,
    payload: {
        request: {
            method: 'POST',
            url: `/payments/coupon`,
            data
        }
    }
})

const resetFields = () => {
    return {
        type: types.resetFields
    }
}


export const paymentActions = {
    resetError,
    changeField,
    getPaymentPlans,
    subscribe,
    resetFields,
    validateCoupon
}
