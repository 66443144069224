/**
 * Generic modal component
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'

const Modal = ({
	show,
	children,
	className,
	onModalClose,
	isHeaderShown,
     customModal,
     overlayClosesModal = true
}) => {
	if (!show) {
		return null
	}
	const handleClickOverlay = e => {
		if (e.defaultPrevented) {
			return
		}
		overlayClosesModal && closeModal()
	}

	const handleClickModalContainer = e => {
		e.preventDefault()
		return
	}

	const closeModal = () => {
		if (onModalClose && typeof onModalClose === 'function') {
			onModalClose()
		}
	}

	const renderHeader = () => {
		return (
			<div className="modal__container-closebtn" onClick={closeModal}>
				<i className="material-icons">close</i>
			</div>
		)
	}

	const modalClass = ['modal']

	if (className) {
		modalClass.push(className)
	}

	if (show) {
		modalClass.push('show')
	} else {
		modalClass.push('hide')
	}

	return (
		<div className={modalClass.join(' ')} onClick={handleClickOverlay}>
             <div className={customModal ? "modal__custom" : "modal__container"} onClick={handleClickModalContainer}>
                 {isHeaderShown && renderHeader()}
                 <div className="modal-content">{children}</div>
             </div>
        </div>
	)
}

export default Modal