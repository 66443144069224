/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

// Login
import { loginReducer, loginSelectors } from './login'
// User
import { userReducer, userSelectors } from './user'
// form
import { registrationFormReducer, registrationFormSelectors } from './registrationForm'
// payment
import { paymentReducer, paymentSelectors } from './payment'

// export root reducer
export const rootReducer = combineReducers({
	router: connectRouter(history),
	login: loginReducer,
	user: userReducer,
	registrationForm: registrationFormReducer,
	payment: paymentReducer
})

// export history
export { history }

// export getters/ selectors
export { loginSelectors, userSelectors, registrationFormSelectors, paymentSelectors }
