import React, { useState } from 'react'
import CouponForm from './CouponValidation'
import Layout from '../../components/layouts/CoreLayout'
import Meta from '../../components/Meta'
import RegisterModal from './CouponRegistration'
import Success from './Success'

const CouponPage = () => {
	const [coupon, setCoupon] = useState()
	const [success, setSuccess] = useState(false)

	return (
		<Layout>
			<Meta title='Kampanja' />
			{!coupon && <CouponForm setCoupon={setCoupon} />}
			{coupon && !success ? (
				<RegisterModal
					showRegisterModal={!!coupon}
					setShowRegisterModal={setCoupon}
					couponData={coupon}
					onSuccess={() => setSuccess(true)}
				/>
			) : null}
			{success && <Success />}
		</Layout>
	)
}
export default CouponPage
