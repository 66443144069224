import React from 'react'
import { Link } from 'react-router-dom'

const ReelResult = ({ data }) => {
	return (
		<Link className='originals-reel__result' to={`/shows/${data.id}`}>
			<img className='originals-reel__result__image' src={data.imageUrl} />
			<p>{data.mainAuthor} </p>
		</Link>
	)
}

export default ReelResult
