import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { pageTitle } from './constants'
import ProtectedRoute from './components/auth/ProtectedRoute'
import Home from 'pages/Home'
import Register from 'pages/Register'
import RegistrationForm from 'pages/RegistrationForm'
import PaymentPage from 'pages/PaymentPage'
import Account from 'pages/Account'
import ResetPasswordSuccess from 'pages/ResetPassword'
import ResetPasswordFail from 'pages/ResetPasswordFail'
import ForgetPassword from 'pages/ForgetPassword'
import ShowView from 'pages/Show'
import AudiobookView from 'pages/Audiobook'
import couponForm from 'pages/Coupon'

import useSnackbarInternal from './hooks/snackbar'

const Routes = () => {
	const location = useLocation()
	const { send } = useSnackbarInternal()

	useEffect(() => {
		if (location.pathname === '/user/password/failed') {
			send('Palautuslinkki on vanhentunut')
		}
	}, [])

	return (
		<Switch>
			<ProtectedRoute
				loginPop
				path='/account'
				component={Account}
				title={pageTitle.account}
			/>
			<Route
				exact
				path='/password/success'
				component={ResetPasswordSuccess}
				title={pageTitle.resetPassword}
			/>
			<Route
				exact
				path='user/password/fail'
				component={ResetPasswordFail}
				title={pageTitle.resetPasswordFail}
			/>
			<Route
				exact
				path='/forgotPassword'
				component={ForgetPassword}
				title={pageTitle.forgetPassword}
			/>
			<Route path='/register' component={Register} title={pageTitle.register} />
			<Route path='/form' component={RegistrationForm} title={pageTitle.form} />
			<Route
				path='/payment'
				component={PaymentPage}
				title={pageTitle.payment}
			/>
			<Route path='/shows' component={ShowView} title={pageTitle.show} />
			<Route
				path='/audiobooks/:id'
				component={AudiobookView}
				title='Podit - audiokirja'
			/>
			<Route
				path='/coupon'
				component={couponForm}
				title='Podit - Lunasta koodi'
			/>
			<Route path='/' component={Home} title={pageTitle.home} />
		</Switch>
	)
}

export default Routes
