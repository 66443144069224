//
/**
 * Reducer helpers
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */
const defaultError = {
	status: null,
	message: 'Oho! Jotain meni pieleen',
	detail: 'Oho! Jotain meni pieleen'
}
/**
 * Format and humanize error message
 * to show specific error message in UI
 *
 * @param {Object} error object from server
 * @return {Object} status code + title + detail
 */
function normalizeError(error) {
	if (!error.response.data) {
		return {
			message: 'Oho! Jotain meni pieleen'
		}
	}
	const errorData = error.response
		? normalizeErrorData(error.response.data)
		: null

	if (!errorData) {
		return defaultError
	}

	// const errorObject = errorData.errors[0]

	return errorData
}

function normalizeErrorData(data) {
	switch (data.message || data.error) {
		case 'Already registered':
		case 'Already registered with google or facebook':
			return {
				message: 'Tämä sähköpostiosoite on jo rekisteröity palveluun'
			}
		case 'This email has been registered':
			return {
				message:
					'Tämä sähköpostiosoite on jo rekisteröity palveluun, käytitkö esimerkiksi jo google kirjautumista?'
			}
		case 'Subscription is already activated!':
			return {
				message: 'Tilaus on jo aktivoitu!'
			}
		default:
			return {
				message: 'Oho! Jotain meni pieleen'
			}
	}
}

export { normalizeError }
