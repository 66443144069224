import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import MuiCheckbox from '@material-ui/core/Checkbox'
import { primaryColor } from '../constants'
// Checkbox will be at the start of the view
const CustomCheckbox = withStyles({
	root: {
		color: primaryColor,
		'&$checked': {
			color: primaryColor
		}
	},
	checked: {}
})(props => <MuiCheckbox color='default' {...props} />)

const CheckBox = ({
	checked,
	onChange,
	label,
	error,
	containerClassName,
	color = error ? 'secondary' : 'primary',
	...props
}) => {
	const containerClass = containerClassName
		? [containerClassName].concat(['checkbox__container'])
		: ['checkbox__container']
	return (
		<div>
			<label className={containerClass.join(' ')}>
				<CustomCheckbox
					checked={checked}
					onChange={onChange}
					value={checked}
					color={color}
					{...props}
				/>
				<span className='checkbox-label'>{label !== '' && label}</span>
			</label>
			<p className='input__error'>{error}</p>
		</div>
	)
}

export default CheckBox