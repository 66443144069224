import React from 'react'
import AppleRegistrationButton from './Apple'
import FBRegisterButton from './FB'
import NewGoogleRegisterButton from './NewGoogle'

const RegistrationButtons = ({ onSuccess, onSubmit, onError }) => {
	return (
		<div className='register__content-signInBtns'>
			<NewGoogleRegisterButton
				onSuccess={onSuccess}
				onSubmit={onSubmit}
				onError={onError}
			/>
			<FBRegisterButton
				onSuccess={onSuccess}
				onSubmit={onSubmit}
				onError={onError}
			/>
			<AppleRegistrationButton
				onSuccess={onSuccess}
				onSubmit={onSubmit}
				onError={onError}
			/>
		</div>
	)
}

export default RegistrationButtons
