import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
// actions and reducers
import { registrationFormSelectors, loginSelectors } from 'reducers'
import { registrationFormActions, loginActions } from 'actions'

import Input from 'components/Input'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import withLoadingScreen from 'components/Loading'

import { validateEmail, validatePasswordLength } from './validation'
import {
	SUCCESS_STATUS_CODE,
	ACCEPT_TERMS_TEXT,
	TERMS_REDIRECT_URL,
	CONDITIONS_REDIRECT_URL
} from '../../constants'

const RegistrationForm = props => {
	const [formSubmitted, setFormSubmitted] = useState(false)
	const dispatch = useDispatch()
	const { hideLoading, showLoading, onFormRouting } = props

	const {
		email,
		password,
		rePassword,
		acceptedTerms,
		emailError,
		passwordError,
		rePasswordError,
		error
	} = useSelector(state => ({
		email: registrationFormSelectors.getEmail(state.registrationForm),
		password: registrationFormSelectors.getPassword(state.registrationForm),
		rePassword: registrationFormSelectors.getRePassword(state.registrationForm),
		emailError: registrationFormSelectors.getEmailError(state.registrationForm),
		passwordError: registrationFormSelectors.getPasswordError(
			state.registrationForm
		),
		rePasswordError: registrationFormSelectors.getRePasswordError(
			state.registrationForm
		),
		error: registrationFormSelectors.getError(state.registrationForm),
		acceptedTerms: loginSelectors.getAcceptedTerms(state.login)
	}))

	useEffect(() => {
		dispatch(registrationFormActions.resetFields())
	}, [])

	const onInputChange = e => {
		const { name, value } = e.target
		dispatch(registrationFormActions.changeField(name, value))
		dispatch(registrationFormActions.resetError())
	}
	const formInvalid = () => {
		dispatch(
			registrationFormActions.changeField('emailError', validateEmail(email))
		)
		dispatch(
			registrationFormActions.changeField(
				'passwordError',
				validatePasswordLength(password)
			)
		)
		if (password !== rePassword) {
			dispatch(
				registrationFormActions.changeField(
					'rePasswordError',
					'Salasanat eivät ole samat'
				)
			)
		}
		if (!acceptedTerms) {
			alert(ACCEPT_TERMS_TEXT)
			return !acceptedTerms
		}
		return (
			validateEmail(email) ||
			validatePasswordLength(password) ||
			password !== rePassword
		)
	}
	const onFormSubmit = async event => {
		event.preventDefault()
		if (formInvalid()) {
			return
		} else {
			try {
				const data = {
					email,
					password,
					repeatedPassword: rePassword
				}
				showLoading()
				const { payload } = await dispatch(
					registrationFormActions.onFormSubmit(data)
				)
				if (!!payload && payload.status === SUCCESS_STATUS_CODE) {
					const { data } = payload.data
					localStorage.setItem('token', data.token)
					onFormRouting(payload.status, data.savedUser.id)
				}
			} finally {
				hideLoading()
			}
		}
	}
	const renderFormFields = () => {
		// registration fields
		const formFields = [
			{
				name: 'email',
				value: email,
				label: 'Sähköposti',
				error: emailError,
				type: 'email'
			},
			{
				name: 'password',
				value: password,
				label: 'Salasana',
				error: passwordError,
				type: 'password'
			},
			{
				name: 'rePassword',
				value: rePassword,
				label: 'Salasana uudelleen',
				error: rePasswordError,
				type: 'password'
			}
		]
		return formFields.map((field, key) => (
			<Input
				name={field.name}
				value={field.value || ''}
				onChange={onInputChange}
				key={key}
				label={field.label}
				className='field'
				error={field.error}
				type={field.type}
			/>
		))
	}
	return (
		<div className='registrationForm'>
			{formSubmitted ? (
				<div className='registrationForm__formSection'>
					<span className='success'>Sähköpostin rekisteröinti onnistui</span>
					<Button text='ok' onClick={() => setFormSubmitted(false)} />
				</div>
			) : (
				<>
					<form
						className='registrationForm__formSection'
						onSubmit={onFormSubmit}
					>
						<span className='registrationForm__formSection-heading'>
							Viisas päätös!
						</span>
						<p className='registrationForm__formSection-desc'>
							Luo oma Podit®-tili ja löydä aina hyödyllistä kuunneltavaa.
						</p>

						{error ? <span className='error'>{error.message}</span> : null}
						<div className='registrationForm__formSection-fields'>
							{renderFormFields()}
						</div>
						<div className='register__content-terms'>
							<Checkbox
								checked={acceptedTerms}
								onChange={() =>
									dispatch(
										loginActions.changeField('acceptedTerms', !acceptedTerms)
									)
								}
								label={
									<>
										<label> Hyväksyn Poditin</label>
										<a
											className='link'
											href={TERMS_REDIRECT_URL}
											target='_blank' rel="noreferrer"
										>
											käyttöehdot
										</a>
										<span>ja</span>

										<a
											className='link'
											href={CONDITIONS_REDIRECT_URL}
											target='_blank' rel="noreferrer"
										>
											yksityisyydensuojan.
										</a>
									</>
								}
							/>
							<p className='statement'>
								Hyväksymällä ehdot voit jatkaa käyttäjätunnuksen luomista joko
								käyttämällä sähköpostiosoitettasi tai Google, Apple tai Facebook
								tunnuksiasi
							</p>
						</div>
						<Button text='Seuraava' type='submit' />
					</form>
				</>
			)}
		</div>
	)
}

export default withLoadingScreen(withRouter(RegistrationForm))
