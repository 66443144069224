/**
 * Forget component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
// actions
import { loginActions } from 'actions'
// Components
import withLoadingScreen from 'components/Loading'
import Input from 'components/Input'
import Button from 'components/Button'
import { SUCCESS_STATUS_CODE } from '../../constants'
const regex = {
	// eslint-disable-next-line
	email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}
const ForgetPassword = ({ showLoading, hideLoading }) => {
	const [isEmailSent, setStatus] = useState(false)
	const dispatch = useDispatch()
	const history = useHistory()
	const [email, setEmail] = useState('')

	const onEmailChange = e => {
		setEmail(e.target.value)
	}
	const onSubmit = async e => {
		e.preventDefault()
		try {
			showLoading()
			const res = await dispatch(loginActions.forgetPassword({ email }))
			if (res.payload.data && res.payload.status === SUCCESS_STATUS_CODE) {
				setStatus(true)
			}
		} finally {
			hideLoading()
		}
	}
	const isDisabled = !regex.email.test(email)
	return (
		<div className='forget-password'>
			<div className='forget-password__content'>
				{isEmailSent ? (
					<div>
						<p>Linkki on lähetetty sähköpostiisi onnistuneesti!</p>
						<Button
							text='Selvä!'
							onClick={() => history.push('/')} // need to redirect to app
						/>
					</div>
				) : (
					<form autoComplete='off' onSubmit={onSubmit}>
						<Input
							required
							name='email'
							type='email'
							label='Sähköpostiosoite'
							value={email}
							onChange={onEmailChange}
							className='field'
						/>
						<Button type='submit' text='Lähetä' disabled={isDisabled} />
					</form>
				)}
			</div>
		</div>
	)
}

export default withLoadingScreen(ForgetPassword)
