import React from 'react'

const LinkText = props => {
	const { text, url } = props
	return (
		<a className='link__text' href={url} target='_blank' rel="noreferrer">
			{text}
		</a>
	)
}

export default LinkText
