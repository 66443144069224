import React from 'react'

const Input = ({
	type = 'text',
	name,
	value,
	label,
	placeholder,
	onChange,
	id,
	autoFocus = false,
	disabled,
	inputProps,
	className,
	containerClassName,
	error,
	noBorder,
	maxLength,
	reference,
	...props
}) => {
	const inputClassName = className ? className.split(' ') : []
	if (noBorder) {
		inputClassName.push('input__no-border')
	} else {
		inputClassName.push('input__border')
	}

	const inputContainerClassName = containerClassName
		? containerClassName.split(' ')
		: []
	inputContainerClassName.push('input__container')
	// Show error when input is touched or form is submitted
	return (
		<div className={inputContainerClassName}>
			<div className='input__row'>
				{label ? <label>{label}</label> : null}
				<input
					{...props}
					type={type}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					ref={reference}
					id={id}
					disabled={disabled}
					autoFocus={autoFocus}
					maxLength={maxLength}
					{...inputProps}
					className={inputClassName.join(' ')}
				/>
			</div>
			<p className='input__error'>{error}</p>
		</div>
	)
}
export default Input
