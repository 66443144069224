import React from 'react'
import AppleLogin from 'react-apple-login'
import {
	APPLE_CLIENT_ID,
	APPLE_SIGNUP_URI,
	APPLE_RESPONSE_TYPE,
	APPLE_RESPONSE_MODE,
	ACCEPT_TERMS_TEXT
} from '../../../constants'
import { useSelector } from 'react-redux'
import { loginSelectors } from '../../../reducers/login'

const AppleRegistrationButton = () => {
	const acceptedTerms = useSelector(state =>
		loginSelectors.getAcceptedTerms(state.login)
	)

	return (
		<AppleLogin
			clientId={APPLE_CLIENT_ID}
			redirectURI={APPLE_SIGNUP_URI}
			responseType={APPLE_RESPONSE_TYPE}
			responseMode={APPLE_RESPONSE_MODE}
			designProp={{
				height: 30,
				width: 250,
				color: 'white',
				scale: 4
			}}
			scope='email name'
			render={props => {
				return (
					<p
						onClick={() =>
							acceptedTerms ? props.onClick() : alert(ACCEPT_TERMS_TEXT)
						}
						className='loginButton apple'
					>
						<i className='fab fa-apple'></i>
						<span className='text'>Sign in with Apple </span>
					</p>
				)
			}}
		/>
	)
}

export default AppleRegistrationButton
