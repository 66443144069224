/**
 * Protected route
 * Only let user access the route if user has been authenticated
 * Otherwise redirect user to login page
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import LoginForm from '../../pages/LoginForm'
import '../../styles/components/_auth.scss'

const ProtectedRoute = ({
	component: Component,
	loginPop,
	unauthenticatedRedirect = '/',
	...rest
}) => {
	const { isLogin: isAuthenticated, loading } = useSelector(
		state => state.login
	)
	if (!isAuthenticated && !loginPop && !loading) {
		return <Redirect to={unauthenticatedRedirect} />
	}

	return (
		<div>
			{loginPop && !isAuthenticated ? (
				<div className='protected__login'>
					<LoginForm />
				</div>
			) : (
				<Route {...rest} component={Component} />
			)}
		</div>
	)
}

export default ProtectedRoute
