/**
 * Login action creator
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { loginTypes as types } from './types'

const changeField = (name, value) => {
	return {
		type: types.changeField,
		name,
		value
	}
}

const facebookLogin = data => ({
	type: types.facebookLogin,
	payload: {
		request: {
			method: 'POST',
			url: `/auth/signin/facebook`,
			data
		}
	}
})
const googleLogin = data => ({
	type: types.googleLogin,
	payload: {
		request: {
			method: 'POST',
			url: `/auth/signin/google`,
			data
		}
	}
})

const newGoogleLogin = data => ({
	type: types.googleLogin,
	payload: {
		request: {
			method: 'POST',
			url: `/auth/v2/signin/google`,
			data
		}
	}
})

const forgetPassword = params => ({
	type: types.forgetPassword,
	payload: {
		request: {
			method: 'GET',
			url: `/auth/password/forgot`,
			params
		}
	}
})
const resetPassword = (token, data) => ({
	type: types.resetPassword,
	payload: {
		request: {
			method: 'POST',
			url: `/auth/password/change`,
			params: {
				Authorization: token
			},
			data
		}
	}
})
const emailLogin = data => ({
	type: types.emailLogin,
	payload: {
		request: {
			method: 'POST',
			url: '/auth/signin',
			data
		}
	}
})
const resetFields = () => {
	return {
		type: types.resetFields
	}
}
export const loginActions = {
	changeField,
	facebookLogin,
	googleLogin,
	forgetPassword,
	resetPassword,
	emailLogin,
	resetFields,
	newGoogleLogin
}
