import React from 'react'
import Checkbox from './Checkbox'
import Input from './Input'

const FormikInput = ({ form, type, field, ...props }) => {
	const error = form.submitCount ? form.errors?.[field.name] : ''
	if (type === 'checkbox') {
		return <Checkbox error={error} {...field} {...props} />
	}

	return <Input type={type} {...props} {...field} error={error} />
}

export default FormikInput
