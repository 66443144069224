// form validation logics

export const required = value => (value ? undefined : 'This field is required')

// email validation
export const validateEmail = value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Epäkelpo sähköpostiosoite'
    } else if (!value) {
        return 'Sähköposti ei voi olla tyhjä'
    } else {
        return null
    }

}


// password length validation
export const validatePasswordLength = value =>
    value && value.length >= 8
        ? undefined
        : 'Salasanan täytyy olla vähintään 8 merkkiä pitkä' 

// text length validation
export const validateNameLength = value =>
    value && value.length >= 2
        ? undefined
        : 'Nimen täytyy olla vähintään 2 merkkiä pitkä' 

export const validateCouponLength = value =>
    value && value.length >= 2
        ? undefined
        : 'Koodin täytyy olla vähintään 2 merkkiä pitkä' 

