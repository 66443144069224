import React from 'react'
import androidStoreLogo from 'assets/google-play-badge.svg'
import appleStoreLogo from 'assets/apple-app-store-badge.svg'

const StoreButtons = () => {
	return (
		<div className='home__appButtons'>
			<a
				onClick={() => {
					window.open(
						'https://apps.apple.com/fi/app/podit/id1570769684?l=fi',
						'_blank'
					)
				}}
				rel='noreferrer noopener'
			>
				<img src={appleStoreLogo} />
			</a>
			<a
				onClick={() => {
					window.open(
						'https://play.google.com/store/apps/details?id=com.podit.podit',
						'_blank'
					)
				}}
				rel='noreferrer noopener'
			>
				<img src={androidStoreLogo} />
			</a>
		</div>
	)
}

export default StoreButtons
