import React from 'react'

const TextButton = ({ children, onClick }) => {
	return (
		<div className='text-button' onClick={onClick}>
			{children}
		</div>
	)
}

export default TextButton
