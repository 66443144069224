import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Layout from '../../components/layouts/CoreLayout'
import { userActions, loginActions } from 'actions'
import { userSelectors } from 'reducers'
import { routerPath } from '../../constants'
import Button from 'components/Button'
import TextButton from '../../components/TextButton'
import PasswordChangeForm from '../../components/PasswordChangeForm'
import Meta from '../../components/Meta'
import { useGTM } from '../../hooks/gtm'
import LoaderComponent from '../../components/Loader'

const Account = props => {
	const { dispatch, user, subscription, t, history } = props
	const { loading } = useSelector(state => state.user)
	const { sendEvent } = useGTM()

	useEffect(() => {
		getSubscription()
		dispatch(userActions.getUser())
	}, [])

	const getSubscription = async () => {
		await dispatch(userActions.getSubscriptionPlan())
	}

	const handleStripePortal = async () => {
		const res = await dispatch(userActions.openStripePortal())
		if (res.payload.data.data) {
			window.location.href = res.payload.data.data.url
		}
	}

	const handleSignOut = () => {
		sendEvent({
			user_id: user.id,
			userRole: user.userType,
			subscription_type: user.planId || 'manual trial',
			subscription_status: subscription?.status || '',
			event: 'log_out'
		})

		localStorage.removeItem('token')
		dispatch(loginActions.resetFields())
		dispatch(userActions.resetFields())
		history.push(routerPath.home)
	}

	if (loading || !user) {
		return <LoaderComponent />
	}

	return (
		<Layout
			ActionComponent={
				<TextButton onClick={handleSignOut}>Kirjaudu ulos</TextButton>
			}
		>
			<Meta title='Minun tili' />
			<div className='accountPage'>
				<h3 className='accountPage__title'>Tilin tiedot</h3>
				<div className='accountPage-info'>
					<div className='accountPage-info-user'>
						<div className='accountPage-info-user-row'>
							<span className='field'>Nimi</span>
							<span>
								{user.firstName
									? `${user.firstName} ${user.lastName}`
									: 'Ei tiedossa'}
							</span>
						</div>
						<div className='accountPage-info-user-row'>
							<span className='field'>Sähköposti</span>
							<span>{user.email}</span>
						</div>
					</div>

					<h3 className='accountPage__title'>Vaihda salasana</h3>
					<PasswordChangeForm />

					<h3 className='accountPage__title'>Tilaus</h3>
					{subscription ? (
						<>
							<div className='accountPage-info-subscription'>
								<div className='accountPage-info-subscription-row'>
									<span className='field'>Tilaus</span>
									<span>{`${(subscription.plan.amount / 100).toFixed(2)}€ / ${t(
										`PAYMENT.${subscription.plan.interval}`
									)}`}</span>
								</div>
							</div>
							<Button text='Muokkaa tilausta' onClick={handleStripePortal} />
						</>
					) : (
						<div className='accountPage-info-subscription'>
							<h3 className='accountPage__title'>
								Sinulla ei ole voimassa olevaa tilausta
							</h3>
							<Button text='Uusi tilaus' onClick={handleStripePortal} />
						</div>
					)}
				</div>
			</div>
		</Layout>
	)
}

const mapStateToProps = state => ({
	user: userSelectors.getUser(state.user),
	subscription: userSelectors.getSubscription(state.user)
})

export default connect(mapStateToProps)(withTranslation()(withRouter(Account)))
