import React, { useState } from 'react'
import LoginForm from '../pages/LoginForm'
import Modal from './Modal'

const LoginModal = () => {
	const [showLoginModal, setShowLoginModal] = useState(false)

	return (
		<>
			<div className='login-button' onClick={() => setShowLoginModal(true)}>
				Kirjaudu
			</div>
			<Modal
				show={showLoginModal}
				onModalClose={() => setShowLoginModal(false)}
				isHeaderShown
			>
				<LoginForm />
			</Modal>
		</>
	)
}

export default LoginModal
