import TagManager from 'react-gtm-module/dist/TagManager'

export const useGTM = () => {
	const sendEvent = data => {
		const isDisabled = sessionStorage.getItem('disableTracking')
		if (!isDisabled) {
			TagManager.dataLayer({
				dataLayer: data
			})
		}
	}
	return {
		sendEvent
	}
}
