/**
 * Form action creator
 *
 * @author sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2021
 */

import { registrationFormTypes as types } from './types'

const changeField = (name, value) => {
    return {
        type: types.changeField,
        name,
        value
    }
}
const resetError = () => {
    return {
        type: types.resetError
    }
}
const resetFields = () => {
    return {
        type: types.resetFields
    }
}
const onFormSubmit = (data)=>({
    type: types.onFormSubmit,
    payload: {
        request: {
            method: 'POST',
            url: `/auth/signup`,
            data
        }
    }
  
})


export const registrationFormActions = {
    changeField,
    resetError,
    onFormSubmit,
    resetFields
}

