/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
	Elements,
	useElements,
	CardElement,
	useStripe
} from '@stripe/react-stripe-js'

// actions
import { paymentActions } from 'actions'
import { paymentSelectors, userSelectors } from 'reducers'
// component
import Radio from 'components/Radio'
import Checkbox from 'components/Checkbox'
import withLoadingScreen from 'components/Loading'
import PaymentSuccessPage from './Success'

// constants
import {
	PAYMENT_STATUS,
	CARD_ELEMENT_OPTIONS,
	SUCCESS_STATUS_CODE
} from 'constants'
import { withTranslation } from 'react-i18next'
import getTimestampInXDays from '../../helpers/getTimestampInXDays'
import useSnackbar from '../../hooks/snackbar'
import { useGTM } from '../../hooks/gtm'

const PaymentPage = props => {
	const [paymentPlansArr, setPaymentPlans] = useState(props.paymentPlans)
	const { send } = useSnackbar()
	const { sendEvent } = useGTM()
	const [paymentStatus, onPaymentStatusChange] = useState('')
	const [subscriptionPlan, handleSelectSubscriptionPeriod] = useState({})
	const [joinMailingList, setJoinMailingList] = useState(false)
	const [adtractionEmail, setAdtractionEmail] = useState('')

	useEffect(() => {
		getPaymentPlans()

		return () => {
			props.dispatch(paymentActions.resetFields())
		}
	}, [])

	useEffect(() => {
		if (paymentStatus === PAYMENT_STATUS.FAIL) {
			send(
				'Kortin, tietojen tai kampanjakoodin vahvistus epäonnistui. Tarkasta tiedot.',
				6000
			)
		}
	}, [paymentStatus])

	const {
		email,
		firstName,
		lastName,
		showLoading,
		hideLoading,
		coupon,
		t
	} = props
	const getPaymentPlans = async () => {
		try {
			showLoading()
			const res = await props.dispatch(paymentActions.getPaymentPlans())
			if (res.payload.status === SUCCESS_STATUS_CODE) {
				const paymentArr = res.payload.data.data
				const activeProducts = paymentArr.filter(p => p.active === true)
				setPaymentPlans(activeProducts)
				handleSelectSubscriptionPeriod({
					price: (activeProducts[0].prices[0].unit_amount / 100).toFixed(2),
					priceId: activeProducts[0].prices[0].id,
					productId: activeProducts[0].id
				})
				// handleSelectSubscriptionPeriod({
				// 	period: paymentArr[0].name,
				// 	planObj: paymentArr[0]
				// })
			}
		} catch (e) {
		} finally {
			hideLoading()
		}
	}
	if (props.user) {
		props.dispatch(paymentActions.changeField('email', props.user.email))
	}

	const elements = useElements()
	const stripe = useStripe()
	const onFormSubmit = async e => {
		e.preventDefault()
		onPaymentStatusChange('')
		const {
			email,
			firstName,
			lastName,
			showLoading,
			hideLoading,
			userToken
		} = props
		const card = elements.getElement(CardElement)
		const billingData = {
			email
		}
		try {
			showLoading()
			sendEvent({
				subscription_type: subscriptionPlan.priceId,
				event: 'begin_checkout',
				ecommerce: {
					items: [
						{
							item_name: 'Podit subscription',
							item_id: subscriptionPlan.planId,
							item_brand: 'Podit',
							price: subscriptionPlan.price,
							item_category: subscriptionPlan.price > 20 ? 'Yearly' : 'Monthly',
							index: 1,
							quantity: 1,
							user_id: props.user?.id
						}
					]
				}
			})
			// To create token
			const { token, error } = await stripe.createToken(card, billingData)
			if (error) {
			} else {
				const data = {
					planId: subscriptionPlan.priceId,
					token: token.id,
					productId: subscriptionPlan.productId,
					newsLetterSubscription: joinMailingList,
					firstName,
					lastName,
					coupon
				}
				const res = await props.dispatch(
					paymentActions.subscribe(data, userToken)
				)
				if (res.payload.status === SUCCESS_STATUS_CODE) {
					try {
						sendEvent({
							event: 'purchase',
							ecommerce: {
								transaction_id: res.payload.data?.data,
								shipping: 0,
								coupon: coupon || '',
								affiliation: 'app.podit.fi',
								currency: 'EUR',
								value: subscriptionPlan.price,
								items: [
									{
										item_name: 'Podit subscription',
										item_id: subscriptionPlan.planId,
										price: subscriptionPlan.price,
										item_brand: 'Podit',
										item_category:
											subscriptionPlan.price > 20 ? 'Yearly' : 'Monthly',
										item_variant: 'Card payment',
										quantity: 1,
										user_id: props.user?.id
									}
								]
							}
						})
						setAdtractionEmail(props.email)
					} catch (error) {}
					card.clear()
					props.dispatch(paymentActions.changeField('email', ''))
					props.dispatch(paymentActions.changeField('name', ''))
					props.dispatch(paymentActions.changeField('firstName', ''))
					props.dispatch(paymentActions.changeField('lastName', ''))
					onPaymentStatusChange(PAYMENT_STATUS.SUCCESS)
				}
			}
		} catch (e) {
			onPaymentStatusChange(PAYMENT_STATUS.FAIL)
		} finally {
			hideLoading()
		}
	}
	const onFieldChange = e => {
		const { name, value } = e.target
		if (name === 'email') {
			return
		}
		props.dispatch(paymentActions.changeField(name, value))
	}

	const SubmitButton = ({ children }) => (
		<button
			className='paymentPage__stripe__SubmitButton'
			type='submit'
			disabled={!useStripe() || !firstName || !lastName}
		>
			{children}
		</button>
	)

	const renderPaymentForm = () => {
		const fields = [
			{
				name: 'email',
				label: 'Sähköposti',
				value: email,
				placeholder: 'Sähköposti'
			},
			{
				name: 'firstName',
				label: 'Etunimi',
				value: firstName,
				placeholder: 'Etunimi',
				required: true
			},
			{
				name: 'lastName',
				label: 'Sukunimi',
				value: lastName,
				placeholder: 'Sukunimi',
				required: true
			},
			{
				name: 'coupon',
				label: 'Kampanjakoodi',
				value: coupon,
				placeholder: 'Kampanjakoodi',
				required: false
			}
		]
		return (
			<fieldset className='paymentPage__stripe__FormGroup'>
				{fields.map((field, key) => (
					<div className='paymentPage__stripe__FormRow' key={key + field.name}>
						<input
							className='paymentPage__stripe__FormRowInput'
							name={field.name}
							type='text'
							placeholder={field.placeholder}
							value={field.value || ''}
							onChange={onFieldChange}
						/>
					</div>
				))}
			</fieldset>
		)
	}

	return (
		<div className='paymentPage'>
			{paymentStatus === PAYMENT_STATUS.SUCCESS ? (
				<PaymentSuccessPage email={adtractionEmail} monthly={subscriptionPlan.price < 25 ? true : false} />
			) : (
				<div className='paymentPage__paymentSections'>
					<div className='paymentPage__paymentSections-description'>
						<span className='heading'>Täytä maksutiedot</span>
						<p className='details'>
							Podit® on maksullinen palvelu, josta löydät asiasisältöiset
							äänikirjat Suomen johtavilta kustantamoilta sekä eksklusiivisia
							podcasteja.
						</p>
					</div>
					<form onSubmit={onFormSubmit} className='paymentPage__stripe__Form'>
						<div className='subscriptionTypes'>
							{paymentPlansArr.map(option => (
								<div key={option.id} className='subscriptionTypes-product'>
									<div className='subscriptionTypes-productName'>
										{option.name}
									</div>
									<div className='subscriptionTypes-description'>
										{option.description}
									</div>
									<div className='subscriptionTypes-description'>
										Ilmainen kokeilu päättyy{' '}
										{getTimestampInXDays(option.metadata.trial_period_days)}
									</div>
									<div className='subscriptionTypes-option-container'>
										{option.prices.map(price => (
											<div key={price.id}>
												{price.active && (
													<div
														className={`subscriptionTypes-option ${
															price.id === subscriptionPlan.priceId
																? 'selected'
																: ''
														}`}
														onClick={() => {
															handleSelectSubscriptionPeriod({
																price: (price.unit_amount / 100).toFixed(2),
																priceId: price.id,
																productId: option.id
															})
														}}
													>
														<Radio
															label={`${(price.unit_amount / 100).toFixed(
																2
															)} € / ${t(
																`PAYMENT.${price.recurring.interval}`
															)}`}
															checked={price.id === subscriptionPlan.priceId}
														/>
													</div>
												)}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
						{renderPaymentForm()}
						<fieldset className='paymentPage__stripe__FormGroup'>
							<CardElement options={CARD_ELEMENT_OPTIONS} />
						</fieldset>
						<Checkbox
							checked={joinMailingList}
							onChange={() => setJoinMailingList(!joinMailingList)}
							label={
								<>
									<label className='paymentPage__subscribe-to-email'>
										Tilaa uutiskirje
									</label>
									<span className='paymentPage__subscribe-to-email__terms'>
										Tilaamalla uutiskirjeen, annat luvan Podit Oy:lle ja sen
										yhteistyökumppaneille lähettää sinulle markkinointiviestejä
									</span>
								</>
							}
						/>
						<SubmitButton>
							{/* {`Maksa ${subscriptionPlan.price} €`} */}
							Aloita kuuntelu
						</SubmitButton>
					</form>
				</div>
			)}
		</div>
	)
}
const mapStateToProps = state => ({
	email: paymentSelectors.getEmail(state.payment),
	name: paymentSelectors.getName(state.payment),
	firstName: paymentSelectors.getFirstName(state.payment),
	lastName: paymentSelectors.getLastName(state.payment),
	country: paymentSelectors.getCountry(state.payment),
	error: paymentSelectors.getError(state.payment),
	user: userSelectors.getUser(state.user),
	userToken: userSelectors.getToken(state.user),
	paymentPlans: paymentSelectors.getPaymentPlans(state.payment),
	coupon: paymentSelectors.getCoupon(state.payment)
})

export default connect(mapStateToProps)(
	withTranslation()(withRouter(withLoadingScreen(PaymentPage)))
)
