import React from 'react'
import MetaTags from 'react-meta-tags'

const Meta = ({
	title,
	description = 'Podcastit ja audiokirjat sinulle räätälöitynä!',
	url = 'https://app.podit.fi',
	imageUrl = 'https://app.podit.fi/favicon.png'
}) => {
	return (
		<MetaTags>
			<title>Podit - {title}</title>
			<meta id='meta-title' property='og:title' content={title} />
			<meta id='meta-desc' property='og:description' content={description} />
			<meta id='meta-image' property='og:image' content={imageUrl} />
			<meta id='meta-url' property='og:url' content={url} />
			<meta
				id='meta-tw-card'
				name='twitter:card'
				content='summary_large_image'
			/>
		</MetaTags>
	)
}

export default Meta
