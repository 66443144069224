import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'

const LinkButton = props => {
	return (
		<Link {...props} style={{ textDecoration: 'none' }}>
			<Button
				containerClassName={props.containerClassName}
				text={props.children}
			/>
		</Link>
	)
}

export default LinkButton
