/*
 * User reducer
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 * @copyright Vertics Co 2020
 */
import { loginTypes, userTypes, registrationFormTypes } from '../actions/types'

const initialState = {
	user: null,
	loading: false,
	error: null,
	subscription: null
}

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case userTypes.changeField:
			return {
				...state,
				[action.name]: action.value,
				error: initialState.error
			}
		case loginTypes.emailLoginSuccess:
		case loginTypes.facebookLoginSuccess:
		case loginTypes.googleLoginSuccess:
		case registrationFormTypes.onFormSubmitSuccess:
			// eslint-disable-next-line
			const { user } = action.payload.data.data
				? action.payload.data.data
				: action.payload.data
			return {
				...state,
				user
			}
		case userTypes.getUserSuccess:
			// eslint-disable-next-line
			const { data } = action.payload.data
			return {
				...state,
				user: data
			}
		case userTypes.getSubscriptionPlanSuccess:
			return {
				...state,
				subscription: action.payload.data.data
			}
		case userTypes.getSubscriptionPlanFail:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case userTypes.getNewJWTSuccess:
			return {
				...state,
				user: action.payload.data.user,
				token: action.payload.data.accessToken
			}
		case userTypes.resetFields:
			return initialState
		default:
			return state
	}
}

export const userSelectors = {
	getUser: (state = initialState) => state.user,
	getSubscription: (state = initialState) => state.subscription,
	getToken: (state = initialState) => state.token,
	getLoading: (state = initialState) => state.loading
}
