/**
 * Core Layout component
 * Wrap the root component with layout UI components
 * e.g Navigation, Footer, Modal, Alert...
 *
 * @author <@vertics.co>
 *
 */
import React from 'react'
import Nav from './Nav'

const CoreLayout = ({ children, ActionComponent }) => {
	return (
		<div className='coreLayout'>
			<main>
				<Nav ActionComponent={ActionComponent} /> {children}
				<footer>
					<a className='optanon-toggle-display' href='#'>
						Evästeasetukset
					</a>
				</footer>
			</main>
		</div>
	)
}

export default CoreLayout
