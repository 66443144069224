import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ShowView from './Show'
import EpisodeView from './Episode'

const ShowRouter = () => (
	<Switch>
		<Route path='/shows/:id/episodes/:episodeId' component={EpisodeView} />
		<Route path='/shows/:id' component={ShowView} />
	</Switch>
)

export default ShowRouter
