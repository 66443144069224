import React from 'react'
import useSnackbarInternal from './snackbar'

export const useAppLinking = () => {
	const { send } = useSnackbarInternal()
	const platform = navigator.userAgent

	return {
		linkToApp: url => {
			location.replace(url)
			setTimeout(() => {
				send(
					<div>
						Lataa sovelluksemme
						<a
							style={{ color: '#fff' }}
							href={
								platform === 'Android'
									? 'https://play.google.com/store/apps/details?id=com.podit.podit'
									: 'https://apps.apple.com/fi/app/podit/id1570769684?l=fi'
							}
						>
							{' '}
							TÄÄLTÄ
						</a>
					</div>,
					8000
				)
			}, 1000)
		}
	}
}
