import React, { useEffect, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'

import { connect, useDispatch } from 'react-redux'

import withLoadingScreen from 'components/Loading'
import Modal from 'components/Modal'
import RegistrationForm from 'pages/RegistrationForm'
import PaymentPage from 'pages/PaymentPage'
import Layout from '../../components/layouts/CoreLayout'
// actions
import { loginActions } from 'actions'
import { loginSelectors } from 'reducers'
import Meta from '../../components/Meta'
import { SUCCESS_STATUS_CODE } from 'constants'
import RegistrationButtons from './RegistrationButtons'
import useSnackbarInternal from '../../hooks/snackbar'
import qs from 'qs'
import { useGTM } from '../../hooks/gtm'

const RegisterPage = props => {
	const dispatch = useDispatch()
	const { send } = useSnackbarInternal()
	const { sendEvent } = useGTM()
	const [state, setState] = useState({
		isRegisterWithEmail: false,
		showPaymentForm: false
	})

	useEffect(() => {
		const { search } = props.location
		if (search && search.includes('error')) {
			const { error } = qs.parse(search.slice(1))
			send(error)
		}
	}, [])

	useEffect(() => {
		dispatch(loginActions.changeField('error', null))
		const search = props.location.search
		const showPayment = new URLSearchParams(search).get('paymentpage')

		if (showPayment === 'true' && props.user) {
			setState({ showPaymentForm: true })
			sendEvent({
				user_id: props.user.id,
				event: 'sign_up'
			})
		}
		return () => {
			dispatch(loginActions.changeField('acceptedTerms', false))
		}
	}, [props.user])

	const onFormRouting = (code, userId) => {
		if (code && code === SUCCESS_STATUS_CODE) {
			setState({ showPaymentForm: true })
			window.scrollTo({ top: 0 })
			props.hideLoading()
			sendEvent({
				user_id: userId,
				event: 'sign_up'
			})
		}
	}

	const { isRegisterWithEmail, showPaymentForm } = state
	const { user } = props

	if (user?.active) {
		return <Redirect to='account' />
	}

	return (
		<Layout>
			<Meta title={'Rekisteröidy'} />
			<div className='register'>
				<div
					className={`register__content ${
						showPaymentForm ? 'register__hide-content' : ''
					}`}
				>
					{props.error ? <span className='error'>error</span> : null}
					<RegistrationButtons
						onSuccess={userId => onFormRouting(SUCCESS_STATUS_CODE, userId)}
						onSubmit={props.showLoading}
						onError={e => {
							props.hideLoading()
							if (e.response?.status === 409) {
								send(
									`Käyttäjä on jo rekisteröity. Kirjaudu sisään sillä menetelmällä, jolla rekisteröidyit.`
								)
							} else {
								if (e.message) {
									send(`Rekisteröinti epäonnistui! ${e.message}`)
								}
							}
						}}
					/>
					<div className='register__content-seperator'>
						<span className='line'></span>
						<span className='text'>Tai</span>
						<span className='line'></span>
					</div>
					<RegistrationForm onFormRouting={onFormRouting} />
				</div>
				{showPaymentForm && (
					<div
						className={`register__payment-container animate-bottom ${
							showPaymentForm ? 'register__show-paymentform' : ''
						}`}
					>
						<PaymentPage />
					</div>
				)}
				{isRegisterWithEmail ? (
					<Modal
						show={isRegisterWithEmail}
						title='modal'
						onModalClose={() => onFormRouting(false)}
						isHeaderShown={true}
					>
						<RegistrationForm onFormRouting={onFormRouting} />
					</Modal>
				) : null}
			</div>
		</Layout>
	)
}

const mapStateToProps = state => ({
	error: loginSelectors.getError(state.login),
	acceptedTerms: loginSelectors.getAcceptedTerms(state.login),
	user: state.user.user
})
export default connect(mapStateToProps)(
	withRouter(withLoadingScreen(RegisterPage))
)
