import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	PODIT_BACKEND_URL
} from '../../../constants'
import Axios from 'axios'
import { loginTypes } from '../../../actions/types'
import {
	GoogleLogin as NewGoogleLogin
} from '@react-oauth/google'

const NewGoogleRegisterButton = ({ onSuccess, onError }) => {
	const [isSmallScreen, setIsSmallScreen] = useState(false)
	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 900px)')
		setIsSmallScreen(mediaQuery.matches)

		const handleResize = e => {
			setIsSmallScreen(e.matches)
		}
		mediaQuery.addEventListener('change', handleResize)

		return () => {
			mediaQuery.removeEventListener('change', handleResize)
		}
	}, [])
	// TODO: Check that acceptedTerms are accepted
	// const { acceptedTerms } = useSelector(state => ({
	// 	acceptedTerms: loginSelectors.getAcceptedTerms(state.login)
	// }))
	const dispatch = useDispatch()
	const googleRegister = async res => {
		const { credential: token } = res
		const data = { token }

		try {
			const res = await Axios.post(
				`${PODIT_BACKEND_URL}/auth/v2/signup/google`,
				data
			)
			if (res.status === 200) {
				dispatch({
					type: loginTypes.googleLoginSuccess,
					payload: res.data
				})
				localStorage.setItem('token', res.data.data.token)
				onSuccess(res.data.data.user.id)
			}
		} catch (e) {
			onError(e)
		}
	}


	if (isSmallScreen) {
		return (
			<div className='login_signInBtns-newGoogle'>
				<NewGoogleLogin
					type='icon'
					shape='square'
					size='large'
					text='signup_with'
					onSuccess={googleRegister}
				/>
			</div>

		)
	}

	return (
		<NewGoogleLogin
			shape='square'
			width='305'
			text='signup_with'
			onSuccess={googleRegister}
		/>
	)
}

export default NewGoogleRegisterButton
