import React from 'react'
import Loader from 'react-loader-spinner'
import {colors} from 'constants'
const LoaderComponent = () => {
	return (
		<Loader
			color={colors.theme}
			height={50}
			width={50}
			type='Oval'
			className='loader-alone'
		/>
	)
}
export default LoaderComponent