/**
 * Form reducer
 *
 * @author sravanthi <sravanthi@vertics.co>
 *
 * @copyright Vertics Co 2021
 */
import { registrationFormTypes as types } from 'actions/types'
import { normalizeError } from './utils'

const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    rePassword: '',
    emailError: '',
    nameError: '',
    passwordError: '',
    rePasswordError: '',
    loading: false,
    error: null,
    formSubmitted: false
}

export const registrationFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.changeField:
            return {
                ...state,
                [action.name]: action.value,
                error: initialState.error
            }
        case types.resetError:
            return {
                ...state,
                nameError: '',
                emailError: '',
                passwordError: '',
                rePasswordError: ''
            }
        case types.onFormSubmit: {
            return {
                ...state,
                loading: true
            }
        }
        case types.onFormSubmitSuccess:
            return {
                ...initialState,
                formSumbitted: true,
                email: action.payload.data.data.email
            }
        case types.onFormSubmitFail:
            return {
                ...state,
                loading: false,
                formSumbitted: false,
                error: normalizeError(action.error)
            }
        case types.resetFields:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export const registrationFormSelectors = {
    getEmail: (state = initialState) => state.email,
    getFirstName: (state = initialState) => state.firstName,
    getLastName: (state = initialState) => state.lastName,
    getPassword: (state = initialState) => state.password,
    getRePassword: (state = initialState) => state.rePassword,
    getEmailError: (state = initialState) => state.emailError,
    getNameError: (state = initialState) => state.nameError,
    getPasswordError: (state = initialState) => state.passwordError,
    getRePasswordError: (state = initialState) => state.rePasswordError,
    getLoading: (state = initialState) => state.loading,
    getError: (state = initialState) => state.error,
    getIsFormSubmitted: (state = initialState) => state.formSubmitted
}
