import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PODIT_BACKEND_URL } from '../../../constants'
import Layout from '../../../components/layouts/CoreLayout'
import LinkButton from '../../../components/LinkButton'
import Button from '../../../components/Button'
import Loader from '../../../components/Loader'
import { Link } from 'react-router-dom'
import NotFoundView from '../NotFoundView'
import Meta from '../../../components/Meta'
import OriginalsReel from '../../../components/OriginalsReel'
import { useAppLinking } from '../../../hooks'

// Todo: Should be sharing components with show view instead of cp

const EpisodeView = ({ match }) => {
	const { linkToApp } = useAppLinking()
	const [episode, setEpisode] = useState()
	const [error, setError] = useState(false)

	useEffect(() => {
		(async function() {
			try {
				const result = await axios.get(
					`${PODIT_BACKEND_URL}/content/episodes/${match.params.episodeId}`
				)
				setEpisode(result.data.data)
			} catch (error) {
				setError(true)
			}
		})()
	}, [])

	if (!episode && !error) {
		return <Loader />
	}

	if (error) {
		return <NotFoundView />
	}

	const handleListenClick = () => {
		linkToApp(`podit://shows/${episode.show.id}/episodes/${episode.id}`)
	}

	return (
		<Layout>
			<Meta
				title={episode.title}
				description={episode.description}
				imageUrl={episode.imageUrl}
				url={`https://app.podit.fi/shows/${episode.show.id}/episodes/${episode.id}`}
			/>
			<div className='show'>
				<img
					className='show__image'
					src={episode.mainImageUrl || episode.imageUrl}
					alt='image of the show'
				/>
				<div className='show__details'>
					<h2> {episode.title}</h2>

					<h6 className='show__details__author-text'>
						{episode.mainAuthor}
						{' - '}
						<Link to={`/shows/${episode.show.id}`}> {episode.show.title} </Link>
					</h6>

					<div className='show__details__category-wrapper'>
						{episode.categories.map(category => (
							<div className='category' key={category.id}>
								{category.name}
							</div>
						))}
					</div>

					<div className='show__action-wrapper'>
						<Button
							containerClassName='action-button'
							text='Kuuntele poditissa'
							onClick={handleListenClick}
						></Button>
						<LinkButton to='/register' containerClassName='action-button'>
							Aloita ilmainen 14 päivän kokeilu
						</LinkButton>
					</div>
				</div>
			</div>

			<div className='episode-description-wrapper'>{episode.description}</div>

			<OriginalsReel />
		</Layout>
	)
}

export default EpisodeView
