/**
 * Login reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { loginTypes as types, userTypes } from '../actions/types'

const initialState = {
	email: null,
	password: null,
	loading: true,
	error: null,
	facebookData: null,
	isLogin: false,
	googleData: null,
	acceptedTerms: false,
	userData: null
}

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.changeField:
			return {
				...state,
				[action.name]: action.value,
				error: initialState.error
			}
		case types.facebookLoginSuccess:
			return {
				...state,
				facebookData: action.payload.data,
				isLogin: true
			}
		case types.googleLoginSuccess:
			return {
				...state,
				googleData: action.payload.data,
				isLogin: true
			}
		case types.emailLoginSuccess:
			return {
				...state,
				userData: action.payload.data,
				isLogin: true
			}
		case userTypes.getUserSuccess:
			return {
				...state,
				userData: action.payload.data,
				isLogin: true,
				loading: false
			}
		case types.emailLoginFail:
		case types.facebookLoginFail:
		case types.googleLoginFail:
			return {
				...state,
				loading: false,
				error: action.error
			}

		case types.resetFields:
			return initialState

		default:
			return state
	}
}

export const loginSelectors = {
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error,
	getIsLogin: (state = initialState) => state.isLogin,
	getAcceptedTerms: (state = initialState) => state.acceptedTerms
}
