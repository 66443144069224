import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PODIT_BACKEND_URL } from '../../constants'
import ReelResult from './ReelResult'

const OriginalsReel = () => {
	const [originals, setOriginals] = useState([])

	const getOriginals = async () => {
		const { data } = await Axios.get(
			PODIT_BACKEND_URL + '/shows/originals'
		)

		setOriginals(data.data)
	}

	useEffect(() => {
		getOriginals()
	}, [])

	return (
		<div className='originals-reel'>
			<h6>
				Tutustu myös Podit Original -sisältöihin, joita et muista palveluista
				löydä:
			</h6>
			<div className='originals-reel__wrapper'>
				{originals.map(original => (
					<ReelResult key={original.id} data={original} />
				))}
			</div>
		</div>
	)
}

export default OriginalsReel
