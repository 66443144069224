/**
 * Forget component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React from 'react'

const ResetPasswordFail = () => {
	return <div className='forget-password__expired'>Linkki on vanhentunut</div>
}

export default ResetPasswordFail
