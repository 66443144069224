import React from 'react'
import { useSnackbar } from 'react-simple-snackbar'

const useSnackbarInternal = snackbarProps => {
	const [open] = useSnackbar(snackbarProps)

	return {
		send: (message, timeout = 3000) => open(<div>{message}</div>, timeout)
	}
}

export default useSnackbarInternal
