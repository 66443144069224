import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import MuiRadio from '@material-ui/core/Radio'
import { radioButtonColor } from '../constants'

const CustomRadioButton = withStyles({
	root: {
		color: radioButtonColor,
		'&$checked': {
			color: radioButtonColor
		}
	},
	checked: {}
})(props => <MuiRadio color='default' {...props} />)

const Radio = ({ label, containerClassName, ...props }) => {
	const containerClass = containerClassName
		? [containerClassName].concat(['radio__container'])
		: ['radio__container']
	return (
		<label className={containerClass.join(' ')}>
			<CustomRadioButton {...props} />
			<span className={'radio-button-label'}>{label}</span>
		</label>
	)
}

export default Radio