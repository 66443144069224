import React, { useEffect } from 'react'
import StoreButtons from '../../components/StoreButtons'
import { useGTM } from '../../hooks/gtm'
import { v4 as uuidv4 } from 'uuid'
import md5 from '../../helpers/hashing'

const Success = ({email, monthly}) => {
	const { sendEvent } = useGTM()
	useEffect(() => {
		if (monthly) {
			sendEvent({
				'event': 'Adtraction conversion monthly',
				'transactionId': uuidv4(),
				'md5': md5(email)
			})
		} else {
			sendEvent({
				'event': 'Adtraction conversion yearly',
				'transactionId': uuidv4(),
				'md5': md5(email)
			})
		}
	}, [])
	return (
		<div className='paymentPage__paymentSections'>
			<i className='fas fa-check-circle'></i>
			<span className='text'>
				Maksutietojen vahvistus onnistui! - Voit nyt kirjautua sisään
				sovellukseen
			</span>
			<div className='paymentPage__paymentSections-appButtons'>
				<StoreButtons />
			</div>
		</div>
	)
}

export default Success
