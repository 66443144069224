import React from 'react'

import logo from 'assets/logo.svg'
import { routerPath } from 'constants'
import Button from 'components/Button'
import Layout from '../../components/layouts/CoreLayout'
import Meta from '../../components/Meta'
import StoreButtons from '../../components/StoreButtons'

const Home = props => {
	const { history } = props

	const onSelectButton = () => {
		history.push(routerPath.register)
	}

	return (
		<Layout>
			<Meta title='Etusivu' />
			<div className='home'>
				<div className='home__description'>
					<div className='home__description-logo'>
						<img className='image' src={logo} />
						<p className='slogan'>What you need to hear</p>
					</div>
					<div className='home__description-details'>
						<h2 className='heading'>Business podcastit ja äänikirjat</h2>
						<p className='text'>
							Pysy ajan tasalla ja kehitä itseäsi. Podit® tarjoaa business- ja
							asiapodcastit sekä -äänikirjat yhden palvelun alla. Vain
							Poditista® löydät eturivin business-ihmisten toimittamat Podit
							Originals -podcastit.
						</p>

						<p className='text'>
							Palvelusta löydät paljon kuunneltavaa, jonka avulla kehityt
							asiantuntijana tai johtajana. Palvelu on käytettävissä juuri
							silloin kun itse haluat. Vaikka lenkillä tai työmatkalla.
						</p>
						<p className='text'>
							Liity nyt ja hyödynnä elinikäinen etu. (vuosimaksi 99,99 euroa
							niin kauan kuin tilauksesi on katkeamattomasti voimassa. Säästät
							lähes 80 euroa vuodessa kuukausiliittymään verrattuna)
						</p>
						<Button
							text='Kokeile 14 päivää ilmaiseksi'
							onClick={onSelectButton}
						/>
					</div>
				</div>
				<div className='home__downContainer'>
					<StoreButtons />
				</div>
			</div>
		</Layout>
	)
}

export default Home
