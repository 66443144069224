import React from 'react'
import StoreButtons from '../../components/StoreButtons'

const Success = () => {
	return (
		<div style={{ maxWidth: 768, margin: '0 auto' }}>
			<div className='registrationForm__formSection'>
				<i className='fas fa-check-circle'></i>
				<span className='success'>Rekisteröinti onnistui</span>
				<p className='text description'>
					Sinulle on nyt luotu Podit-tili! Lataa applikaatio, ja kirjaudu äsken
					valitsemillasi tunnuksilla, niin pääset nauttimaan Poditin
					sisällöistä.
				</p>
				<div className='home__downContainer'>
					<StoreButtons />
				</div>
			</div>
		</div>
	)
}

export default Success
