export const formatDate = dateToFormat => {
	// eslint-disable-next-line
	const formattedDate = Intl.DateTimeFormat('fi', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	}).format(dateToFormat)

	return formattedDate
}
