import React from 'react'
import FacebookLogin from 'react-facebook-login'
import {
	FACEBOOK_APP_ID,
	ACCEPT_TERMS_TEXT,
	PODIT_BACKEND_URL
} from 'constants'
import { useDispatch, useSelector } from 'react-redux'
import { loginSelectors } from '../../../reducers/login'
import { loginTypes } from '../../../actions/types'

import Axios from 'axios'
const FBRegisterButton = ({ onSubmit, onSuccess, onError }) => {
	const acceptedTerms = useSelector(state =>
		loginSelectors.getAcceptedTerms(state.login)
	)
	const dispatch = useDispatch()

	const onFacebookLogin = async response => {
		try {
			if (response.status === 'unknown') {
				throw Error('Rekisteröityminen peruutettu')
			}
			onSubmit()
			const { accessToken } = response
			const data = {
				access_token: accessToken
			}
			const res = await Axios.post(
				`${PODIT_BACKEND_URL}/auth/signup/facebook`,
				data
			)
			if (res.status === 200) {
				dispatch({
					type: loginTypes.facebookLoginSuccess,
					payload: res.data
				})
				localStorage.setItem('token', res.data.data.token)
				onSuccess(res.data.data.user.id)
			}
		} catch (e) {
			onError(e)
		}
	}

	return (
		<div>
			{!acceptedTerms ? (
				<span className='customFb' onClick={() => alert(ACCEPT_TERMS_TEXT)}>
					<button type='button' className='kep-login-facebook metro'>
						<p className='loginButton facebook'>
							<i className='fab fa-facebook-f' aria-hidden='true'></i>
							<span className='text'>Register with Facebook</span>
						</p>
					</button>
				</span>
			) : (
				<FacebookLogin
					appId={FACEBOOK_APP_ID}
					fields='name,email,picture'
					callback={onFacebookLogin}
					designProp={{ height: 30, width: 250 }}
					disableMobileRedirect={true}
					textButton={
						<p className='loginButton facebook'>
							<i className='fab fa-facebook-f'></i>
							<span className='text'>Register with Facebook</span>
						</p>
					}
				/>
			)}
		</div>
	)
}

export default FBRegisterButton
