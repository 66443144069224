import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PODIT_BACKEND_URL } from '../../constants'
import LinkButton from '../../components/LinkButton'
import Button from '../../components/Button'
import Layout from '../../components/layouts/CoreLayout'
import EpisodeListing from './EpisodeListing'
import Loader from '../../components/Loader'
import NotFoundView from './NotFoundView'
import Meta from '../../components/Meta'
import OriginalsReel from '../../components/OriginalsReel'
import { useAppLinking } from '../../hooks'

const ShowView = ({ match }) => {
	const { linkToApp } = useAppLinking()
	const [show, setShow] = useState()
	const [error, setError] = useState(false)

	useEffect(() => {
		(async function() {
			if ((!show || show.id !== parseInt(match.params.id)) && !error) {
				try {
					const result = await axios.get(
						`${PODIT_BACKEND_URL}/content/shows/${match.params.id}?listing=episodes&listingOrder=latest`
					)
					setShow(result.data.data)
				} catch (error) {
					setError(true)
				}
			}
			window.scrollTo(0, 0)
		})()
	}, [show, error, match.params.id])

	if (!show && !error) {
		return <Loader />
	}

	if (error) {
		return <NotFoundView />
	}

	const handleListenClick = () => {
		linkToApp(`podit://shows/${show.id}`)
	}

	return (
		<Layout>
			<Meta
				title={show.title}
				description={show.description}
				imageUrl={show.imageUrl}
				url={`https://app.podit.fi/shows/${show.id}`}
			/>
			<div className='show'>
				<img
					className='show__image'
					src={show.imageUrl}
					alt='image of the show'
				/>
				<div className='show__details'>
					<h2> {show.title}</h2>
					<h6 className='show__details__author-text'>{show.authors[0].name}</h6>

					<div className='show__details__category-wrapper'>
						{show.categories.map(category => (
							<div className='category' key={category.id}>
								{category.name}
							</div>
						))}
					</div>

					<div className='show__action-wrapper'>
						<Button
							containerClassName='action-button'
							text='Kuuntele poditissa'
							onClick={handleListenClick}
						></Button>
						<LinkButton to='/register' containerClassName='action-button'>
							Aloita ilmainen 14 päivän kokeilu
						</LinkButton>
					</div>
				</div>
			</div>
			<p style={{ marginTop: 20, marginBottom: 20 }}> {show.description} </p>
			<EpisodeListing episodes={show.podcast} />
			<OriginalsReel />
		</Layout>
	)
}

export default ShowView
