/**
 * Root client file
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */

// WORK INSTRUCTIONS:
// - Please do not remove the above header. It protects the IPR intrests of our company.
// - Please replace the author name with your own full real name.

// React stuffs
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { Elements as StripeElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import './services/i18n'
import { store, persistor } from './store'

// history from reducer
import { history } from './reducers'

// Styling
import './styles/index.scss'

// Components
import App from './App'
import { STRIPE_API_KEY, GOOGLE_CLIENT_ID } from './constants'
import { GoogleOAuthProvider } from '@react-oauth/google'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_API_KEY, {
	locale: 'auto'
})

// Eslint wants us to use React 18, but we are using 16
// eslint-disable-next-line
ReactDOM.render(
	<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
		<StripeElements stripe={stripePromise}>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<PersistGate loading={null} persistor={persistor}>
						<App />
					</PersistGate>
				</ConnectedRouter>
			</Provider>
		</StripeElements>
	</GoogleOAuthProvider>,
	document.getElementById('app')
)
