import React from 'react'
import FormikInput from './FormikInput'
import { Formik, Field, Form } from 'formik'
import Button from './Button'
import * as yup from 'yup'
import useSnackbar from '../hooks/snackbar'
import Axios from 'axios'
import { PODIT_BACKEND_URL } from '../constants'

const ValidationSchema = yup.object().shape({
	password: yup
		.string()
		.required('Vaadittu kenttä')
		.min(8, 'Salasanan tulee olla vähintään 8 merkkiä'),
	passwordRe: yup
		.string()
		.oneOf([yup.ref('password'), null], 'Salasanat eivät täsmää')
})

const PasswordChangeForm = () => {
	const { send } = useSnackbar()

	const handleSubmit = async ({ password }) => {
		try {
			await Axios.put(`${PODIT_BACKEND_URL}/user`, {
				password: password
			})
			send('Salasanan vaihto onnistui')
		} catch (error) {
			send('Salasanan vaihto epäonnistui')
		}
	}
	return (
		<Formik
			initialValues={{
				password: '',
				passwordRe: ''
			}}
			onSubmit={handleSubmit}
			validationSchema={ValidationSchema}
		>
			<Form>
				<Field
					component={FormikInput}
					label='Salasana'
					name='password'
					required
					type='password'
				/>
				<Field
					component={FormikInput}
					label='Salasana uudelleen'
					name='passwordRe'
					required
					type='password'
				/>
				<Button type='submit' text='Vaihda salasana' />
			</Form>
		</Formik>
	)
}

export default PasswordChangeForm
