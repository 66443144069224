import React from 'react'
import {
	TERMS_REDIRECT_URL,
	CONDITIONS_REDIRECT_URL
} from '../../src/constants'
import LinkText from './LinkText'

const TermsAndService = () => {
	return (
		<div className='row'>
			<p>
				Hyväksyn Poditin
				<LinkText url={TERMS_REDIRECT_URL} text=' käyttöehdot ' />
				ja
				<LinkText url={CONDITIONS_REDIRECT_URL} text=' yksityisyydensuojan. ' />
			</p>
		</div>
	)
}

export default TermsAndService
