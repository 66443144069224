import React from 'react'
import { Formik, Field, Form } from 'formik'
import { PODIT_BACKEND_URL } from '../../constants'
import FormikInput from '../../components/FormikInput'
import useSnackbar from '../../hooks/snackbar'
import * as yup from 'yup'
import Button from '../../components/Button'
import axios from 'axios'
import TermsAndService from '../../components/TermsAndService'
import { formatDate } from '../../utils'

const ValidationSchema = yup.object().shape({
	email: yup
		.string()
		.required('Vaadittu kenttä')
		.email('Tarkasta osoite'),
	firstName: yup
		.string()
		.required('Vaadittu kenttä')
		.min(2, 'Etunimen tulee olla vähintään 2 merkkiä'),
	lastName: yup
		.string()
		.required('Vaadittu kenttä')
		.min(2, 'Sukunimen tulee olla vähintään 2 merkkiä'),
	password: yup
		.string()
		.required('Vaadittu kenttä')
		.min(8, 'Salasanan tulee olla vähintään 8 merkkiä'),
	repeatedPassword: yup
		.string()
		.required('Vaadittu kenttä')
		.min(8, 'Salasanan tulee olla vähintään 8 merkkiä')
		.oneOf([yup.ref('password'), null], 'Salasanat eivät täsmää'),
	acceptedTerms: yup.bool().isTrue('Hyväksy käyttöehdot')
})

const CouponRegistrationForm = ({ couponData, onSuccess }) => {
	const { coupon, code } = couponData
	const { send } = useSnackbar()
	const couponDate = new Date()
	couponDate.setMonth(couponDate.getMonth() + coupon.duration_in_months)

	const onFormSubmit = async data => {
		try {
			const postPayload = { couponCode: code, ...data }
			const {
				data: { data: payload }
			} = await axios.post(`${PODIT_BACKEND_URL}/auth/signup`, postPayload)

			onSuccess(payload)
		} catch (e) {
			send(e.response?.data?.error || e.message)
		}
		// Not sure why this is here since setFormSubmitted isn't defined anymore (2023-11-30 / Antti Vuorenmaa)
		// } finally {
		// 	setFormSubmitted(false)
		// }
	}

	return (
		<div className='registrationForm'>
			<h2>Kiitos!</h2>
			<p className='text'>
				{`Lahjakorttisi on lunastettu heti rekisteröinnin jälkeen, ja sinulla on vapaa kuunteluoikeus Poditiin ${formatDate(
					couponDate
				)} asti.`}
			</p>
			<p className='text'>
				Rekisteröidy alla, niin pääset nauttimaan Poditista. Mikäli haluat
				jatkaa kuunteluaikaasi, voit liittää tiliisi uudet maksutiedot
				myöhemmin.
			</p>
			<div className='registrationForm__formSection-coupon'>
				<div className='registrationForm__formSection-fields'>
					<Formik
						validationSchema={ValidationSchema}
						initialValues={{
							email: '',
							firstName: '',
							lastName: '',
							password: '',
							repeatedPassword: '',
							acceptedTerms: false
						}}
						onSubmit={onFormSubmit}
					>
						<Form>
							<Field component={FormikInput} label='Sähköposti' name='email' />
							<Field component={FormikInput} label='Etunimi' name='firstName' />
							<Field component={FormikInput} label='Sukunimi' name='lastName' />
							<Field
								component={FormikInput}
								label='Salasana'
								name='password'
								type='password'
							/>
							<Field
								component={FormikInput}
								label='Salasana uudelleen'
								name='repeatedPassword'
								type='password'
							/>
							<Field
								component={FormikInput}
								type='checkbox'
								name='acceptedTerms'
								label={<TermsAndService />}
							/>
							<Button type='submit' text='Rekisteröidy' />
						</Form>
					</Formik>
				</div>
			</div>
		</div>
	)
}

export default CouponRegistrationForm
