import React, { useState } from 'react'
import TextButton from 'components/TextButton'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Button from 'components/Button'
import Input from 'components/Input'
import withLoadingScreen from 'components/Loading'

import FacebookLogin from 'react-facebook-login'
// import GoogleLogin from 'react-google-login'
import AppleLogin from 'react-apple-login'
import {
	GoogleLogin as NewGoogleLogin
} from '@react-oauth/google'

import { loginActions } from 'actions'

import {
	FACEBOOK_APP_ID,
	APPLE_CLIENT_ID,
	APPLE_RESPONSE_TYPE,
	APPLE_RESPONSE_MODE,
	APPLE_REDIRECT_URI,
	routerPath
} from 'constants'
import useSnackbar from '../../hooks/snackbar'
import { useGTM } from '../../hooks/gtm'

// TODO Separate logic from presentation
const LoginForm = props => {
	const { history, dispatch, showLoading, hideLoading } = props
	const { send } = useSnackbar()
	const { sendEvent } = useGTM()

	const [userEmail, setUserEmail] = useState('')
	const [userPassword, setUserPassword] = useState('')

	const handleLogin = loginType => async eventOrResponse => {
		let res
		try {
			showLoading()
			if (loginType === 'fb') {
				res = await onFacebookLogin(eventOrResponse)
			}
			if (loginType === 'google') {
				res = await handleNewGoogleLogin(eventOrResponse)
			}
			if (loginType === 'epw') {
				res = await handleRegularLogin(eventOrResponse)
			}
			const { user, token } = res.payload.data.data

			if (user) {
				sendEvent({
					user_id: user.id,
					userRole: user.userType,
					subscription_type: user.planId,
					subscription_status: user.active,
					event: 'login'
				})
				hideLoading()

				localStorage.setItem('token', token)
				if (user.trialActivated) {
					history.push(routerPath.account)
				} else {
					history.push(routerPath.payment)
				}
			}
		} catch (e) {
			sendError()
			setUserPassword('')
		} finally {
			hideLoading()
		}
	}

	const sendError = () => {
		send(
			'Kirjautuminen epäonnistui, tarkasta tiedot. Käytithän samaa menetelmää kuin rekisteröityessäsi?',
			5000
		)
	}

	const handleRegularLogin = async event => {
		event.preventDefault()
		document.activeElement?.blur()
		const data = {
			email: userEmail,
			password: userPassword
		}

		return await dispatch(loginActions.emailLogin(data))
	}

	const handleNewGoogleLogin = async response => {
		const { credential: token } = response
		return await props.dispatch(loginActions.newGoogleLogin({ token }))
	}

	const onFacebookLogin = async response => {
		const { accessToken } = response
		const data = {
			access_token: accessToken
		}
		return await dispatch(loginActions.facebookLogin(data))
	}

	return (
		<div className='login'>
			<h3>Kirjaudu sisään</h3>
			<div className='login__content-signInBtns'>
				<div className='login_signInBtns-logo'>
						<div className='login_signInBtns-newGoogle'>
							<NewGoogleLogin
								type='icon'
								shape='square'
								size='large'
								text='signup_with'
								onSuccess={handleLogin('google')}
								onError={() => {
									sendError()
									setUserPassword('')
								}}
							/>
						</div>
					<FacebookLogin
						appId={FACEBOOK_APP_ID}
						fields='name,email,picture'
						callback={handleLogin('fb')}
						designProp={{ height: 56, width: 56 }}
						disableMobileRedirect={true}
						textButton={
							<p className='loginButton facebook'>
								<i className='fab fa-facebook-f'></i>
							</p>
						}
					/>
					<AppleLogin
						clientId={APPLE_CLIENT_ID}
						redirectURI={APPLE_REDIRECT_URI}
						responseType={APPLE_RESPONSE_TYPE}
						responseMode={APPLE_RESPONSE_MODE}
						designProp={{ height: 30, width: 250, color: 'white', scale: 4 }}
						scope='email name'
						render={props => {
							return (
								<p onClick={props.onClick} className='loginButton apple'>
									<i className='fab fa-apple'></i>
								</p>
							)
						}}
					/>
				</div>
			</div>
			<div className='login__content-seperator'>
				<span className='line'></span>
				<span className='text'>Tai</span>
				<span className='line'></span>
			</div>
			<form className='login__formSection-fields' onSubmit={handleLogin('epw')}>
				<Input
					name='email'
					value={userEmail}
					onChange={e => setUserEmail(e.target.value)}
					label='Sähköposti'
					className='field'
					type='text'
				/>
				<Input
					name='password'
					value={userPassword}
					onChange={e => setUserPassword(e.target.value)}
					label='Salasana'
					className='field'
					type='password'
				/>
				<Button
					onClick={handleLogin('epw')}
					type='submit'
					text='Kirjaudu'
					containerClassName='login__login-button'
				/>
				<div
					style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}
				>
					<Link to='/forgotPassword'>
						<TextButton> Unohtuiko salasana? </TextButton>
					</Link>
				</div>
			</form>
		</div>
	)
}

export default connect()(withRouter(withLoadingScreen(LoginForm)))
