import React from 'react'
import Button from 'components/Button'
import { routerPath } from '../../constants'
import { useHistory } from 'react-router-dom'

const ErrorModal = ({ onModalClose }) => {
	const history = useHistory()
	return (
		<>
			<div className='coupon__error-modal'>
				<div className='coupon__description-details'>
					<h2 className='heading'>Voi ei!</h2>
					<br />
					<p className='coupon__error-text'>
						Järjestelmämme ei tunnista lahjakorttikoodiasi. Otatko yhteyttä
						lahjakortin antajaan tai sähköpostitse meihin info@podit.fi, niin
						katsotaan mistä on kysymys. Lisääthän sähköpostiin
						lahjakorttikoodisi, joka ei toiminut.
					</p>
					<br />
					<p className='text'>Avaa tili ilman lahjakorttia</p>
					<Button
						text='Rekisteröidy'
						onClick={() => history.push(routerPath.register)}
					/>
					<br />
					<p className='text'>Yritä uudelleen</p>
					<Button text='Yritä uudestaan' onClick={() => onModalClose(false)} />
				</div>
			</div>
		</>
	)
}

export default ErrorModal
